import styled from "@emotion/styled";
import { FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, LG, MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MID_HEADER_DESKTOP_MENU } from "constants/vp-sizes";
import { HIDDEN, LAYER_TWO } from "constants/z-index";
import colors from "styles/colors";
import { WHITE_COLOR_NAME } from "styles/color-consts";
export const StyledPanoramaCardsCarouselTabsComponent = styled.div`
  position: relative;
  width: calc(100% - 50px);
  margin: auto;
  max-width: ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;

  .carousel {
    position: absolute;
    right: calc(100vw / 1920 * 50);
    top: 50%;
    transform: translateY(-50%);
    width: 550px;
    z-index: ${LAYER_TWO};

    .slick-slider .slick-list {
      display: flex;
      transform: scale(0.9);
      background-color: ${colors[WHITE_COLOR_NAME]};
      border-radius: 40px;

      .slick-track {
        padding: 0;
        display: flex;

        .slick-slide {
          display: flex;
          padding: 0 8px;

          .carousel-item {
            height: 100%;
          }
        }
      }
    }
  }

  .background-asset-container {
    position: relative;

    .asset-inner {
      width: 100%;

      &:not(:first-of-type) {
        position: absolute;
        left: 0;
        top: 0;
      }

      &.selected {
        opacity: 1;
      }

      opacity: 0;
      transition: opacity ${({ tabTransitionSpeed })=>tabTransitionSpeed}ms;
      border-radius: 40px;
      object-fit: contain;
      z-index: ${HIDDEN};

      .react-player-video-component video {
        border-radius: 40px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: start;
    flex-direction: column;

    .quote {
      margin-bottom: 0;
    }

    .author {
      gap: 15px;

      .company {
        font-weight: 400;
      }
    }
  }

  @media (max-width: ${LG}px) {
    .carousel {
      right: 0;

      .slick-slider .slick-list {
        transform: scale(0.8);
      }
    }
  }

  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) {
    .carousel {
      right: -30px;

      .slick-slider .slick-list {
        transform: scale(0.74);
      }
    }
  }

  @media (max-width: ${MID_HEADER_DESKTOP_MENU}px) {
    .carousel {
      .stats-section {
        display: none;
      }
    }
`;
